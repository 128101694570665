@import 'swiper/swiper-bundle.css';
/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;


html,
body {
    height: 100%;
    /* background-color: #F6F4F1; */
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

.a-primary-color {
    color: #B79086;
}

.a-secondary-color {
    color: #577778;
}

.green-snackbar {
    --mdc-snackbar-container-color: #E1F8F0;
    --mdc-snackbar-supporting-text-color: #212B36;
    border-left: 6px solid #34D399;
}

::-ms-reveal {
    display: none;
    /*elimina el show password de microsoft edge para poder usar el de luna nueva*/
}

html,
body {
    height: 100%;
    /* background-color: #F6F4F1; */

}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

.gm-style-iw+div {
    display: none;
}

.gm-style-iw>button {
    display: none !important;
}

::ng-deep .priceSlider {
    width: fit-content !important;
    min-width: 500px !important;
    margin-top: 8px;
    margin-bottom: 8px;
    border-radius: 8px;
    border: 1px solid var(--Cafe-Luna-Nueva, #372121);
    background: var(--Blanco-Luna-Nueva, #fffefe);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    overflow: hidden;
    height: fit-content;
  }

.guestMenu  {
    width: 345px !important;
    max-width: 365px !important;
    padding: 0 10px !important;
    border-radius: 8px !important;
    border: 1px solid #000 !important;
    background: var(--Blanco-Luna-Nueva, #fffefe) !important;
    display: block !important;
}

.dateMenu {
    overflow: hidden;
    height: fit-content;
    padding:0px 10px 0px 10px;
}
.mat-mdc-menu-panel{
    min-width: 0px !important;
    max-width: none !important;
    overflow: hidden !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: #D1C8BA33 !important; /* Tu color deseado */
    border-radius: 8px;
    box-shadow: 0px 2px 4px 0px #D1C8BA;
}

mat-label{
    padding-left: 0px;
    font-size: 16px;
    line-height: 18.2px;
}

.mat-mdc-select{
    color: #372121 !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-floating-label, .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-floating-label--float-above {
    color: #577778 !important;
}
.mdc-tooltip__surface.mdc-tooltip__surface-animation {
	background-color: #577778 !important;
}