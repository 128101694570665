@use '@angular/material' as mat;

@include mat.all-component-typographies();
@include mat.core();

@import "assets/styles/colors/ln-green";
@import "assets/styles/colors/ln-gray";
@import "assets/styles/colors/ln-yellow";
@import "assets/styles/colors/ln-red";
@import "assets/styles/colors/ln-white";
@import "assets/styles/colors/ln-beige";
@import "assets/styles/colors/ln-coffee";

$luna-nueva-frontend-primary: mat.define-palette(mat.$indigo-palette);
$luna-nueva-frontend-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
$luna-nueva-frontend-warn: mat.define-palette(mat.$red-palette);

$luna-nueva-frontend-theme: mat.define-light-theme((
        color: (
                primary: $luna-nueva-frontend-primary,
                accent: $luna-nueva-frontend-accent,
                warn: $luna-nueva-frontend-warn,
        )
));

$ln-green-primary: mat.define-palette($md-green);
$ln-gray-accent: mat.define-palette($md-gray);
$ln-yellow-warn: mat.define-palette($md-yellow);
$ln-red-primary: mat.define-palette($md-red);
$ln-white-primary: mat.define-palette($md-white);
$ln-beige-primary: mat.define-palette($md-beige);
$ln-coffee-primary: mat.define-palette($md-coffee);

$ln-buttons-theme: mat.define-light-theme((
        color: (
                primary: $ln-green-primary,
                accent: $ln-gray-accent,
                warn: $ln-yellow-warn,
        )
));

$ln-slide-toggle-theme: mat.define-light-theme((
        color: (
                primary: $ln-gray-accent,
                accent: $ln-green-primary,
                warn: $ln-yellow-warn,
        )
));

$ln-checkbox-theme: mat.define-light-theme((
        color: (
                primary: $ln-green-primary,
                accent: $ln-gray-accent,
                warn: $ln-yellow-warn,
        )
));

$ln-spinner-theme: mat.define-light-theme((
        color: (
                primary: $ln-green-primary,
                accent: $ln-gray-accent,
                warn: $ln-yellow-warn,
        )
));

$ln-mat-form-field-theme: mat.define-light-theme((
        color: (
                primary: $ln-green-primary,
                accent: $luna-nueva-frontend-accent,
                warn: $luna-nueva-frontend-warn,
        )
));

$ln-mat-slider: mat.define-light-theme((
        color: (
                primary: $ln-red-primary,
                accent: $ln-red-primary,
                warn: $ln-red-primary,
        )
));

$ln-mat-chip: mat.define-light-theme((
        color: (
                primary: $ln-white-primary,
                accent: $luna-nueva-frontend-accent,
                warn: $luna-nueva-frontend-warn,
        )
));

$ln-mat-expansion: mat.define-light-theme((
        color: (
                primary: $ln-beige-primary,
                accent: $ln-white-primary,
                warn: $luna-nueva-frontend-warn,
        )
));

$option-theme: mat.define-light-theme((
        color: (
                primary: $ln-coffee-primary,
                accent: $ln-gray-accent,
                warn: $ln-yellow-warn,
        )
));
.swiper-pagination-bullet-active {
        background-color: #372121 !important;
    }
    .swiper-button-prev {
        color: #372121 !important;
        font-size: 10px !important;
      }
      
      .swiper-button-next {
        color: #372121 !important;
        font-size: 10px !important;
      }

@include mat.all-component-themes($luna-nueva-frontend-theme);

@include mat.button-theme($ln-buttons-theme);
@include mat.checkbox-theme($ln-checkbox-theme);
@include mat.progress-spinner-theme($ln-spinner-theme);
@include mat.form-field-theme($ln-mat-form-field-theme);
@include mat.slider-theme($ln-mat-slider);
@include mat.chips-theme($ln-mat-chip);
@include mat.expansion-theme($ln-mat-expansion);
@include mat.expansion-color($ln-mat-expansion);
@include mat.slide-toggle-theme($ln-slide-toggle-theme);
@include mat.slide-toggle-color($ln-slide-toggle-theme);
@include mat.option-theme($option-theme);
@include mat.pseudo-checkbox-theme($ln-checkbox-theme);

/* You can add global styles to this file, and also import other style files */
